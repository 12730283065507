var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AdminTable',{attrs:{"id":"admin-steam-bot-proxy","columns":_vm.columns,"title":`Коллекция steam-bot-proxies`,"model-name":"steamBotProxyModel"},scopedSlots:_vm._u([{key:"top-right",fn:function({ query }){return [_c('q-btn',{attrs:{"label":"Создать","color":"primary","push":"","size":"md","rounded":""},on:{"click":function($event){_vm.creationProxyDialog = true}}}),_c('q-dialog',{model:{value:(_vm.creationProxyDialog),callback:function ($$v) {_vm.creationProxyDialog=$$v},expression:"creationProxyDialog"}},[_c('q-card',{style:(_vm.$q.platform.is.mobile ? 'min-width: 400px' : 'min-width: 500px')},[_c('q-card-section',{staticClass:"row items-center q-pb-none"},[_c('div',{staticClass:"text-h6"},[_vm._v("Создание прокси")]),_c('q-space'),_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],attrs:{"icon":"close","flat":"","round":"","dense":""}})],1),_c('q-card-section',[_c('ApolloMutation',{attrs:{"mutation":gql => gql`
                mutation createSteamBotProxy(
                  $url: String!
                  $comment: String
                ) {
                  createSteamBotProxy(url: $url, comment: $comment) {
                    _id
                  }
                }
              `},on:{"error":error =>
                _vm.$q.notify({
                  message: error.message,
                  multiLine: true,
                  type: 'negative'
                }),"done":() => {
                query.refetch();
              }},scopedSlots:_vm._u([{key:"default",fn:function({ mutate, loading, error }){return [_c('q-form',{on:{"submit":function($event){return _vm.submit(mutate)}}},[_c('q-input',{attrs:{"label":"Прокси-лист","type":"textarea"},model:{value:(_vm.creationProxyList),callback:function ($$v) {_vm.creationProxyList=$$v},expression:"creationProxyList"}}),_c('q-input',{attrs:{"label":"Комментарий"},model:{value:(_vm.creationProxyComment),callback:function ($$v) {_vm.creationProxyComment=$$v},expression:"creationProxyComment"}}),_vm._v(" http "),_c('q-toggle',{attrs:{"color":"primary","label":"socks5"},model:{value:(_vm.proxyType),callback:function ($$v) {_vm.proxyType=$$v},expression:"proxyType"}}),_c('q-btn',{staticClass:"full-width q-mt-md",attrs:{"label":"Создать","loading":loading,"color":"primary","size":"lg","push":"","type":"submit"}})],1)]}}],null,true)})],1)],1)],1),_c('q-btn',{attrs:{"label":"Купить","color":"warning","push":"","size":"md","rounded":""},on:{"click":_vm.openBuyDialog}}),_c('q-dialog',{model:{value:(_vm.buyProxyDialog),callback:function ($$v) {_vm.buyProxyDialog=$$v},expression:"buyProxyDialog"}},[_c('q-card',{style:(_vm.$q.platform.is.mobile ? 'min-width: 400px' : 'min-width: 500px')},[_c('q-card-section',{staticClass:"row items-center q-pb-none"},[_c('div',{staticClass:"text-h6"},[_vm._v("Покупка прокси")]),_c('q-space'),_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],attrs:{"icon":"close","flat":"","round":"","dense":""}})],1),_c('q-card-section',[_c('ApolloMutation',{attrs:{"mutation":gql => gql`
                mutation buySteamBotProxy(
                  $proxyType: Boolean!
                  $period: String!
                  $count: String!
                  $country: String!
                  $onlyCopy: Boolean!
                  $isAimCopyFormat: Boolean!
                ) {
                  buySteamBotProxy(
                    proxyType: $proxyType
                    period: $period
                    count: $count
                    country: $country
                    onlyCopy: $onlyCopy
                    isAimCopyFormat: $isAimCopyFormat
                  )
                }
              `},on:{"error":error =>
                _vm.$q.notify({
                  message:
                    'Проверьте баланс proxyline \n->\n' + error.message,
                  multiLine: true,
                  type: 'negative'
                }),"done":value => {
                _vm.$q.notify({
                  message: value.data.buySteamBotProxy.includes('OK')
                    ? `Успех`
                    : 'Успешно скопировано',
                  multiLine: true,
                  type: 'positive'
                });

                if (!value.data.buySteamBotProxy.includes('OK')) {
                  _vm.copyToClipboard(value.data.buySteamBotProxy);
                }

                query.refetch();
              }},scopedSlots:_vm._u([{key:"default",fn:function({ mutate, loading, error }){return [_c('q-form',{on:{"submit":function($event){mutate({
                    variables: {
                      proxyType: _vm.proxyType,
                      period: String(_vm.proxyPeriod),
                      count: _vm.proxyCount,
                      country: _vm.proxyCountry.value,
                      onlyCopy: _vm.onlyCopy,
                      isAimCopyFormat: _vm.isAimCopyFormat
                    }
                  })}}},[_c('q-select',{attrs:{"label":"Страна","options":_vm.proxyCountryList},model:{value:(_vm.proxyCountry),callback:function ($$v) {_vm.proxyCountry=$$v},expression:"proxyCountry"}}),_c('q-input',{attrs:{"label":"Количество"},model:{value:(_vm.proxyCount),callback:function ($$v) {_vm.proxyCount=$$v},expression:"proxyCount"}}),_c('q-select',{attrs:{"label":"Период","options":[
                    5,
                    10,
                    20,
                    30,
                    60,
                    90,
                    120,
                    150,
                    180,
                    210,
                    240,
                    270,
                    300,
                    330,
                    360
                  ]},model:{value:(_vm.proxyPeriod),callback:function ($$v) {_vm.proxyPeriod=$$v},expression:"proxyPeriod"}}),_c('div',{staticClass:"flex items-center"},[_vm._v(" http "),_c('q-toggle',{attrs:{"color":"primary","label":"socks5"},model:{value:(_vm.proxyType),callback:function ($$v) {_vm.proxyType=$$v},expression:"proxyType"}}),_c('q-space'),_c('q-toggle',{attrs:{"color":"primary","label":"Скоп.(для aim)"},model:{value:(_vm.isAimCopyFormat),callback:function ($$v) {_vm.isAimCopyFormat=$$v},expression:"isAimCopyFormat"}}),_c('q-toggle',{attrs:{"color":"primary","label":"Скоп.(для sp)"},model:{value:(_vm.onlyCopy),callback:function ($$v) {_vm.onlyCopy=$$v},expression:"onlyCopy"}})],1),_c('div',{staticClass:"row q-col-gutter-x-xl"},[_c('q-input',{staticClass:"col-md-4 col-12",attrs:{"label":"Баланс ProxyLine в USD","readonly":""},model:{value:(_vm.proxylineBalance),callback:function ($$v) {_vm.proxylineBalance=$$v},expression:"proxylineBalance"}}),_c('q-input',{staticClass:"col-md-4 col-12",attrs:{"label":"Сумма заказа в USD","readonly":""},model:{value:(_vm.proxylineBuyAmount),callback:function ($$v) {_vm.proxylineBuyAmount=$$v},expression:"proxylineBuyAmount"}}),_c('q-input',{staticClass:"col-md-4 col-12",attrs:{"error":_vm.proxylineBalance - _vm.proxylineBuyAmount < 0,"error-message":"Не хватает баланса","label":"Остаток баланса в USD","value":Number(
                        (_vm.proxylineBalance - _vm.proxylineBuyAmount).toFixed(2)
                      ),"readonly":""}})],1),_c('q-btn',{staticClass:"full-width q-mt-md",attrs:{"label":_vm.onlyCopy || _vm.isAimCopyFormat
                      ? 'Купить и скопировать'
                      : 'Купить и добавить',"loading":loading,"color":"primary","disable":!_vm.proxyCountry ||
                      !_vm.proxyCount ||
                      !_vm.proxyPeriod ||
                      _vm.proxylineBalance - _vm.proxylineBuyAmount < 0,"size":"lg","push":"","type":"submit"}})],1)]}}],null,true)})],1)],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }