
import { Component, Vue, Watch } from "vue-property-decorator";
import AdminTable from "@/components/admin/AdminTable.vue";
import gql from "graphql-tag";
import copyToClipboard from 'quasar/src/utils/copy-to-clipboard.js';;

@Component({
  components: {
    AdminTable
  }
})
export default class AdminSteamBotProxy extends Vue {
  proxyType = true;
  creationProxyList = "";
  creationProxyComment = "";
  creationProxyDialog = false;
  buyProxyDialog = false;
  proxylineBalance = "";
  proxylineBuyAmount = "";
  proxyPeriod = "";
  proxyCount = "";
  proxyCountry = "";
  onlyCopy = false;
  isAimCopyFormat = false;
  proxyCountryList = [
    { label: "Россия", value: "ru" },
    { label: "Казахстан", value: "kz" },
    { label: "Украина", value: "ua" },
    { label: "Германия", value: "de" },
    { label: "США", value: "us" },
    { label: "Норвегия", value: "no" }
  ];
  columns = [
    {
      name: "_id",
      required: true,
      label: "Steam ID",
      field: "_id",
      align: "left",
      type: "string"
    },
    {
      name: "url",
      align: "center",
      label: "url",
      field: "url",
      editable: true,
      type: "string"
    },
    {
      name: "steamBotId",
      align: "center",
      label: "steamBotId",
      field: "steamBotId",
      editable: true,
      type: "string"
    },
    {
      name: "comment",
      align: "center",
      label: "Комментарий",
      field: "comment",
      editable: true,
      type: "string"
    },
    {
      name: "enabled",
      align: "center",
      label: "enabled",
      field: "enabled",
      editable: true,
      type: "boolean"
    },
    {
      name: "rotator",
      align: "center",
      label: "rotator",
      field: "rotator",
      editable: true,
      type: "boolean"
    }
  ];

  async openBuyDialog() {
    this.buyProxyDialog = true;

    const { data } = await this.$apollo.query({
      query: gql`
        query {
          getProxylineBalance
        }
      `
    });

    this.proxylineBalance = data.getProxylineBalance;
  }

  @Watch("proxyCountry")
  @Watch(`proxyCount`)
  @Watch(`proxyPeriod`)
  async getOrderAmount() {
    if (this.proxyCountry && this.proxyCount && this.proxyPeriod) {
      const { data } = await this.$apollo.query({
        query: gql`
          query($period: String!, $count: String!, $country: String!) {
            getAmountBuySteamBotProxy(
              period: $period
              count: $count
              country: $country
            )
          }
        `,
        variables: {
          period: String(this.proxyPeriod),
          count: this.proxyCount,
          country: (this.proxyCountry as any).value
        }
      });

      this.proxylineBuyAmount = data.getAmountBuySteamBotProxy;
    }
  }

  async copyToClipboard(value) {
    return await copyToClipboard(value);
  }

  async submit(mutate) {
    const urls = this.creationProxyList.split("\n").map(proxy => {
      const [ip, port, login, password] = proxy.split(":");

      if (this.proxyType) {
        return `socks5://${login ? `${login}:${password}@` : ""}${ip}:${port}`;
      } else {
        return `http://${login ? `${login}:${password}@` : ""}${ip}:${port}`;
      }
    });

    for await (const url of urls) {
      try {
        await mutate({
          variables: { url, comment: this.creationProxyComment }
        });
      } catch (e) {
        console.error(e);
      }
    }
  }
}
